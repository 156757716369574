@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;

}

label {
  margin: 8px 0 4px;
  font-weight: 600;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.container {
  display: flex;
  align-items: center;
}

.brand-img {
  height: 75px;
}

.text {
  background-color: rgb(0, 0, 0);
  width: 50%;
  color: antiquewhite;
  height: 90vh;
  display: flex;
  align-items: center;
}

h1 {
  font-size: 5rem;
  white-space: normal;
  overflow: auto
}

.background {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  margin-top: -50px;
  /* background-color: aqua; */
  /* background-image: url(./assets/images/cover-min.jpg); */
  /* background:
              linear-gradient(rgba(239, 180, 66, 0.40),
                  rgba(239, 180, 66, 0.40)),
              url(cover.jpg); */
  background:
    linear-gradient(rgba(239, 181, 66, 0.5),
      rgba(68, 133, 95, 0.603)),
    url(./assets/images/cover-min.jpg);
  /* filter: contrast(110%); */
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;

}

.navbar-brand {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.8rem;
  font-weight: bold;

}

.nav-link {
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
}

.display-1 {
  font-weight: 900;
}

.display-5 {
  font-weight: 700;
}

.display-3 {
  font-weight: 700;
}


.center-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-gap {
  gap: 10vh;
}

.logo {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 900;
}

/* 
      .nav {
          font-size: 1.2rem;
          line-height: 1.5;
          font-weight: 600;
      } */

/* .btn-primary {
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
  color: #F6F0E3;
  font-weight: 600;
  background: #44855F;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0.6rem 1.1rem;
  cursor: pointer;
  transition: all 200ms ease-in;
} */

/* .btn-primary:hover {
  background: #2f6e49;
} */

.btn-dark {
  background: #040002;
}

.btn-sec {
  font-family: Montserrat, sans-serif;
  font-size: 1.8rem;
  color: #F6F0E3;
  font-weight: 600;
  background: #040002;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.btn-sec:hover {
  background: #ffbc37;
}

.hero-text h1 {
  font-family: 'Asap', sans-serif;
  color: #ffffff;
  /* line-height: 1.5;
          font-weight: 900; */
  text-align: center;
  text-shadow: 2px 2px 2px rgb(44, 44, 44);
}

.hero-text h2 {
  font-family: 'Asap', sans-serif;
  color: #ffffff;
  /* line-height: 1.5;
          font-weight: 600; */
  text-align: center;
  /* font-size: 3rem; */
  text-shadow: 2px 2px 2px rgb(44, 44, 44);
}

.hero-text .gradient {
  width: 100%;
  height: 90vh;
  background-image: linear-gradient(to right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0));
  display: flex;
  align-items: center;

}

#contain {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  padding: 2em 2em 6em 5em;
  text-align: start;
}

.space-flex {
  display: flex;
  justify-content: space-around;
}

.section-2 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 50px;
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
}

#about {
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
}

#testimonial {
  text-align: center;
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
}

.carousel-item {
  padding: 7% 15%;
  color: #f6f0e3;
}

.about-heading {
  color: #040002;
  font-weight: 600;
}

.torn-paper {
  background-image: url(./assets/images/paperTorn-3.webp);
  /* height: 600px; */
  background-size: cover;
  background-position: bottom;
  height: fit-content;
  padding: 2% 2% 10ch;
  /* margin: 0 20px 20px 50px; */
  font-family: 'Asap', sans-serif;
  /* color: hsl(0, 0%, 24%); */
  line-height: 1.5;
  font-size: 1.5rem;
  /* text-shadow: 1px 1px 2px rgb(218, 218, 218); */
}

.right-panel {
  margin-right: 30px;
}

.right-panel-img {
  display: flex;

}

.sub-heading {
  color: #fff4de;
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
}

.paragraph {
  color: #fff4de;
  line-height: 1.5;
  font-size: 1.2rem;
  padding: 0 30px 0 0;
}



#stats {
  background-color: #F6F0E3;
}

.section-3 {
  padding: 7% 8% 7%;
  background-color: #F6F0E3;
}

.cards-component1 {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 50px;
}

.my-card1 {
  font-size: 1.5rem;
  font-weight: 600;
  width: 260px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 1ch 20%; */
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 0 20px 8px #e0d2b2;
  transition: transform .2s;
}

.my-card:hover {
  transform: scale(1.05);
}

.cards-component {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.my-card {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  /* width: 260px; */
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 1ch 20%; */
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 0 20px 8px #e0d2b2;
  transition: transform .2s;
}



#cta {
  padding: 7% 15%;
  background-color: #efb442;
  text-align: center;
  color: #228a4d;
}

.contact-us {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  color: white;
  padding: 3em;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 32%;
  /* 4:3 Aspect Ratio */
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.cta-heading {
  font-size: 3.5rem;

  font-weight: 800;
}

#contact {
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
}

.logo1 {
  height: 80px;
}

#footer {
  background-color: #F6F0E3;
  text-align: center;
  padding: 20px;
}

.footer-icon {
  margin: 2% 1%;
}

.counter {
  transition: --num 1s;
  counter-set: num var(--num);
  font: 800 30px system-ui;
}

.counter::after {
  content: counter(num);
}

.animate {
  --num: 3458;
}

.animate-1 {
  --num: 56;
}

.animate-2 {
  --num: 205;
}

.animate-3 {
  --num: 40;
}

.animate-4 {
  --num: 1200;
}

.active1 {
  color: #2f6e49;
  font-weight: 800;
}

.header-container {
  /* background-color: #F6F0E3; */
  z-index: 99;
  padding: 15px 30px 40px;
  /* height: 180px; */
  color: rgb(78, 78, 78);
  background-image: linear-gradient(#F6F0E3 0%, #f6f0e3 75%, rgba(0, 0, 0, 0));
}

#event-intro {
  padding: 4% 0;
}

#current-events {
  /* margin-top: -50px; */
  padding: 3% 0;
  background-color: #F6F0E3;
  /* box-shadow: inset 0 -50px 150px #122b1c; */
}

#past-events {
  padding: 4% 0;
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
}

#registration-form {
  padding: 4% 0;
}

.info-table {
  border: 2px solid #040002;
  border-radius: 10px;
  padding: 16px;
}

.regestration-form {
  margin: 4% 0;
  padding: 4% 0;
  border: 2px solid #040002;
  border-radius: 10px;
  padding: 16px;
}

.form-header {
  background-color: rgb(53, 53, 53);
  border-radius: 5px;
  padding: 4px;
}

.events-page {
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blob {
  width: 500px;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8, %3Csvg width=%22100%25%22 height=%22100%25%22 viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 %3E %3Cdefs%3E %3CclipPath id=%22shape%22%3E %3Cpath fill=%22currentColor%22 d=%22M817%2C636.5Q658%2C773%2C464%2C836Q270%2C899%2C212%2C699.5Q154%2C500%2C215.5%2C307Q277%2C114%2C473%2C161Q669%2C208%2C822.5%2C354Q976%2C500%2C817%2C636.5Z%22%3E%3C%2Fpath%3E %3C%2FclipPath%3E %3C%2Fdefs%3E %3Cg clip-path=%22url(%23shape)%22%3E %3Cpath fill=%22%23efb442%22 d=%22M817%2C636.5Q658%2C773%2C464%2C836Q270%2C899%2C212%2C699.5Q154%2C500%2C215.5%2C307Q277%2C114%2C473%2C161Q669%2C208%2C822.5%2C354Q976%2C500%2C817%2C636.5Z%22 %2F%3E %3C%2Fg%3E %3C%2Fsvg%3E");
}

.runner-img {
  height: 500px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.file-upload {
  width: 500px;
}

#photos {
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 5;
  -webkit-column-gap: 0px;
  -moz-column-count: 5;
  -moz-column-gap: 0px;
  column-count: 5;
  column-gap: 0px;
}

.photos img {
  /* Just in case there are inline attributes */
  /* width: 100% !important; */
  height: auto !important;
}

.volunteer-container {
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
}

.donate-container {
  background-color: #2f6e49;
  box-shadow: inset 0 -50px 150px #122b1c;
  color: #fff4de;
}

.volunteer-form {
  background-color: #efb442;
  padding: 2%;
}

.highlight {
  background-image: linear-gradient(to right, #F27121cc, #E94057cc, #8A2387cc);
  background-size: 400% 400%;
  border-radius: 8px;
  padding: 0px 6px;
  animation: gradient 2s ease infinite;
}

.donate-cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

/* div.content p {
  line-height: 1.7rem;
  margin: 30px 0;
} */

div.content li {
  margin-bottom: 10px;
}

.banner {
  font-size: 1.2rem;
  background-color: #2f6e49; /* Set your desired background color */
  color: #fff; /* Set your desired text color */
  text-align: center;
  padding: 20px 10px 10px;
}

.banner a {
  color: #fff; /* Set your desired link color */
  text-decoration: none;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

mark {
  -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
  animation: 1.5s highlight 1.5s 1 normal forwards;
  background-color: none;
  background: linear-gradient(90deg, #F27121cc 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
}

@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}


@media (max-width: 1200px) {
  .photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 1000px) {
  .photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 800px) {
  .photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 400px) {
  .photos {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}


@media (max-width: 420px) {
  .brand-img {
    width: 30vw;
  }

  .torn-paper {
    padding: 2% 12% 10ch;
  }

  .info-table {
    font-size: smaller;
  }

  .events-p {
    font-size: 1rem;
  }

  .blob {
    width: 300px;
    height: 300px;
  }

  .runner-img {
    height: 300px;
    /* width: 300px; */
    /* transform: scale(1.5); */
    object-fit: cover;
    object-position: 0 100%;
    /* -webkit-transform: scaleX(-1);
    transform: scaleX(-1); */
  }
}